import React, { useEffect, useState } from "react";
import { initMercadoPago } from "@mercadopago/sdk-react";
import { CardPayment } from "@mercadopago/sdk-react";
import Navbar from "components/global/navbar";
import Layout from "components/global/layout";
import PageTitle from "components/global/page-title";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { useStore } from "app/store/store";
import Warning from "../components/Warning";
import Loader from "../components/mercado-pago/Placeholder";
import { Link } from "gatsby";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { PIPEDREAM_ROOT_URL } from "../app/constants";
import { createGuide } from "../app/store/applicationReducer";
import { navigate } from "gatsby";
const PUB_KEY = "TEST-5524edfd-8d98-4c68-b7ee-f186a8764386";
// const PUB_KEY = "TEST-bf3bbe51-baa3-4712-8e74-4c6c864896fc";

initMercadoPago(PUB_KEY, { locale: "es-MX" });

const hasResults = (result) => {
  return Object.keys(result).length > 0 && !result.error;
};

const PROCESS = {
  type: "info",
  title: "Procesando pago...",
  message: "Tu pago está siendo procesado por mercado pago",
}

const ERROR = {
  type: "error",
  title: "Error del Servidor",
  message: "Intentelo más tarde",
}

export default function RealizarPago() {
  const [globalState, middleWareDispatcher, guide] = useStore();
  const [process, setProcess] = useState(true)
  const result = globalState?.quote?.result;
  const { costo } = result;

  const onSubmit = async (formData) => {
    const formDataSend = { ...formData, ["transaction_amount"]: costo };
    try {
      const response = await fetch(PIPEDREAM_ROOT_URL + "/process_payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataSend),
      });
      const json = await response.json();
      console.log("JS", json)
      const { data } = json;
      const { id, status } = data;
      if (status === "rejected") {
        console.log("rechazado")
        sessionStorage.setItem("paymentData", JSON.stringify({ id, status }));
        navigate("/pago-rechazado")
      } else if (status === "approved") {
        console.log("aprovado")
        sessionStorage.setItem("paymentData", JSON.stringify({ id, status }));
        middleWareDispatcher(createGuide(globalState, data));
        navigate("/pago-completado")
      }
      // * Desmontar
    } catch (err) {
      // ! cachar errores de MP
      console.log(err)
      const { stack } = err
      console.log(err);
      if (stack) {
        eventBus.publish("NOTIFICATION", {
          type: "error",
          title: "Error del Servidor",
          message: "Intentelo más tarde",
        });
      } else {
        navigate("/pago-rechazado")
      }
    }
  };

  const initialization = {
    amount: 1000,
  };

  const onError = async (error) => {
    console.log("ERROR", error);
  };

  const onReady = async () => {
    setProcess(false)
    console.log(process)
    console.log("CARGANDO...")
  };

  const customization = {
    visual: {
      style: {
        customVariables: {
          textPrimaryColor: "#6b7280",
          textSecondaryColor: "#9ca3af",
          inputBackgroundColor: "",
          formBackgroundColor: "#F5F6F7",
          baseColor: "#FF4D29",
          baseColorFirstVariant: "#6b7280",
          baseColorSecondVariant: "#34C759",
        },
      },
      texts: {
        cardholderName: {
          label: "Nombre que aparece en la tarjeta",
        },
        expirationDate: {
          label: "Vencimiento",
        },
        securityCode: {
          label: "Código de seguridad",
        },
        formSubmit: "Realizar Pago",
      },
    },
  };
  // console.log(process)

  return (
    <>
      <Navbar />
      <Layout>
        <>
          {
            hasResults(result) ?
              <>
                <PageTitle>Resumen del envío</PageTitle>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-4 bg-gray-400 bg-opacity-10 py-6 rounded-md mb-56 md:mb-40">
                  <div className="px-6">
                    <h2 className="text-primary-light-red font-display font-bold text-lg uppercase">
                      Aerocharter plus
                    </h2>
                    <div className="flex justify-between text-sm">
                      <span>{result.estado_origen}</span>
                      <ChevronRightIcon className="w-4 h-4" />
                      <span>{result.estado_destino}</span>
                    </div>
                    <div className="py-8">
                      <h3 className="text-primary-dark-blue text-xs">
                        Total Envío
                      </h3>
                      <p className="uppercase text-primary-light-green text-3xl font-display font-bold">
                        <span>
                          <span>$ {parseFloat(result.costo).toFixed(2)} MX </span>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-center items-center flex-col px-2">
                    {process && <Loader
                      textInfo={"Cargando Formulario De Mercado Pago..."}
                    />
                    }
                    <>
                      <CardPayment
                        initialization={initialization}
                        onSubmit={onSubmit}
                        onReady={onReady}
                        onError={onError}
                        customization={customization}
                      />
                      <div className="px-4 w-full flex justify-center items-center" style={{ margin: '' }}>
                        <Link
                          to="/realizar-envio"
                          state={{ step: "receiverDetails" }}
                          className="btn-shape text-white w-full max-w-full flex justify-center items-center bg-primary-light-red hover:bg-opacity-90"
                        >
                          <ArrowLeftIcon className="w-4 h-4" /><span className="pl-2">Atras</span>
                        </Link>
                      </div>
                    </>

                    {/* } */}
                  </div>
                </div>
              </>
              : <Warning />
          }
        </>
      </Layout >
    </>
  );
}
